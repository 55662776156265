<template>
  <div class="content" style="margin-top: -50px">
    <vue-whatsapp-widget
      phoneNumber="+524421867547"
      companyName="tulum.party (Gaby)"
      textReply="Typically replies within 10-15 mins (9am - 10pm)"
      companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
    />

    <div class="header text-center" style="margin-bottom: -35px">

      <HomeMainImage
      />
      <br>

    </div>
    <div class="row">
      <div class="col-md-12">
        <card style="z-index: 1; text-align: center; padding: 10px">
          <template slot="header">


            <p class="text-2xl">
              <strong>Tulum, Mexico </strong>is a world-wide recognized spot for
              its electronic music <a href="#event-line"> parties</a>,
              <router-link :to="{path: '/tulum-clubs'}"> nightclubs</router-link>
              and festive
              <router-link :to="{path: '/tulum-nightlife'}">nightlife</router-link>
              . Surrounded by impressive
              landscapes, <strong>Tulum</strong> congregates the
              <strong>best world DJ's along open minded travelers</strong> from
              all around the globe.
            </p>
          </template>
          <template slot="default">
            <div style="text-align: center !important">
              <br>
              <small>PLAN A | <strong>SIDE B</strong></small>
              <br/>
              <br/>
              <div
                class="card"
                style="
                  z-index: 1;
                  width: 300px;
                  margin-left: auto;
                  margin-right: auto;
                  left: 0;
                  right: 0;
                "
              >
                <p style="font-weight: bold">
                  “Whenever you find yourself on the side of the majority, it is
                  time to pause and reflect.” -Mark Twain.
                </p>
              </div>


<!--              <router-link-->
<!--                :to="{ name: 'Community' }"-->
<!--                class="btn btn-call-for-action"-->
<!--              >-->
<!--                Join our community-->
<!--              </router-link>-->

              <a href="https://www.instagram.com/tulum.party/"  class="btn btn-call-for-action">
                &nbsp;&nbsp;FOLLOW US @
              Instagram
              </a>
              <br/>
              <br/>
              <br/>

              <hr/>
            </div>
          </template>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-8 offset-md-2">
        <card
          class="card"
          style="
            z-index: 1;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            margin-top: -80px;
            max-width: 350px;
            background-color: #151414;
            padding: 15px;
          "
        >
          <template slot="header">
            <br/>
            <ClubActivity></ClubActivity>
          </template>
          <slot>
            <div class="text-center">
              <a
                href="https://wa.me/524421867547?text=Thanks%20for%20contacting%20us!%20How%20can%20we%20help%3F"
                v-on:click="onQuickReserveClick('Party Support')"
                class="btn btn-sm btn-primary font-weight-bold d-inline-xs"
                style="
                  width: 80%;

                  margin-left: auto;
                  margin-right: auto;
                  left: 0;
                  right: 0;
                  padding: 10px;
                  border: 1px solid silver;
                "
              >
                📲 INFO & RESERVATIONS
              </a>

              <br/>
              <br/>

              <br/>
              <a href="mailto:bookings@tulum.party">bookings@tulum.party</a>

              <br/>

              <small> We operate from 9AM - 10PM / Answer within 15 mins</small>
              <br/>
              <br/>
              <small style="color: #fff; font-weight: bolder"
              >NO EXTRA FEE</small
              >
              <br>
              <small>Show your love, reserve through us.</small>

              <br/>
              <small>Following our dream since 2018</small>
            </div>
          </slot>
        </card>
        <card
          class="card text-center"
          style="z-index: 1; background-color: #151414"
        >
          <template slot="header">
            <router-link :to="{ name: 'Clubs' }">
              <h3
                class="tulum-party-title"
                style="margin-bottom: 0;"
              >
                <br>TOP CLUBS
              </h3>


              <small style="font-weight: bold;" class="tulum-party-subtitle-bg">
                We take you into the Tulum's Elite Club Scene
              </small>
              <br>
              <div style="margin-top: 10px; padding: 4px" class="btn btn-group">
                <router-link
                  :to="{ name: 'BeachClubs' }"
                  class="btn btn-primary btn-sm card-animation-on-hover"
                  style="background-color: #7bf6cd; z-index: 100; padding: 30px;"
                >DAY CLUBS
                </router-link>
                <router-link
                  :to="{ name: 'Clubs' }"
                  style="
                  z-index: 100;
                  padding: 30px;
                  color: #7bf6cd !important;
                  background-color: black;
                "
                  class="btn btn-primary btn-sm card-animation-on-hover"
                >NIGHT CLUBS
                </router-link>
              </div>
              <h5>
                <strong>Electronic Music</strong> is all over the place.
                <br/>High end restaurants,
                <router-link :to="{ path: '/tulum-day-beach-clubs' }">
                  Beach clubs
                </router-link>
                &
                <router-link :to="{ path: '/tulum-clubs' }">Night clubs</router-link>
              </h5>
              <!--              <img-->
              <!--              v-lazy="clubsSpirt+'?w=280&h=90&fit=clamp&duotone=black,f67ba4'"-->
              <!--                style="box-shadow: #000000 10px 10px 2px"-->
              <!--                height="90px"-->
              <!--                width="280px"-->
              <!--                alt="Tulum Top Clubs"-->
              <!--              />-->
            </router-link>

            <hr style="border: 2px; color: mediumaquamarine"/>
            <h6><strong><a href="/tulum-day-beach-clubs">Day</a> or <a href="/tulum-clubs">Night</a> its party time
              !</strong></h6>
            <br>
          </template>
        </card>
      </div>
    </div>

    <div class="row" id="event-line" style="margin-top: 25px">
      <img
        v-lazy="bgPalm"
        v-if="showPalms"
        class="d-inline-sm d-md-none"
        alt="tulum.party crazy palm"
        style="
          position: fixed;
          left: 5%;
          bottom: 5%;
          opacity: 0.1;
          width: 100%;
          z-index: 0;
        "
      />
      <div class="row card" style="z-index: 100;background-color: #0e0e0e">

        <div class="col-sm-12">
          <h4 class="section-header">
            WHATS HOT?</h4>
          <div class="card card-animation-on-hover text-left" style="margin: 10px;">
            <router-link :to="{ name: 'PartyCalendar' }">
              <img alt="Party Calendar" height="50px" width="100px"
                   style="margin-right: 10px; box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 15px 0px; border-radius: 0px;"
                   v-lazy="calendarImg+'?w=100&h=50&fit=clamp'">
              <div style="display: inline; vertical-align: top; color: white; position: absolute;margin-left: 10px">
                <span style="font-weight: 700 !important;font-size: 11px">PARTY CALENDAR</span><br>
                <p style="font-size: 11px"> DO NOT MISS A THING !</p>
              </div>
            </router-link>
          </div>
          <div class="card card-animation-on-hover text-left" style="margin: 10px;">
            <router-link :to="{ path: '/tulum-new-year-festival-2024' }">
              <img alt="NYE/HIGH SEASON 2025" height="50px" width="100px"
                   style="margin-right: 10px; box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 15px 0px; border-radius: 0px;"
                   v-lazy="calendarImg2+'?w=100&h=50&fit=clamp'">
              <div style="display: inline; vertical-align: top; color: white; position: absolute;margin-left: 10px">
                <span style="font-weight: 700 !important;font-size: 11px">NYE/HIGH SEASON 2025</span><br>
                <p style="font-size: 11px"> DANCE TO CELEBRATE</p>
              </div>
            </router-link>
          </div>
          <div class="card card-animation-on-hover text-left" style="margin: 10px;">
            <router-link :to="{ path: '/brands/zamna-tulum' }">
              <img alt="ZAMNA TULUM" height="50px" width="100px"
                   style="margin-right: 10px; box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 15px 0px; border-radius: 0px;"
                   v-lazy="zamnaTulum+'?w=100&h=50&fit=clamp'">
              <div style="display: inline; vertical-align: top; color: white; position: absolute;margin-left: 10px">
                <span style="font-weight: 700 !important;font-size: 11px">ZAMNA TULUM</span><br>
                <p style="font-size: 11px"> THE JUNGLE IS CALLING</p>
              </div>
            </router-link>
          </div>


        </div>
      </div>


    </div>
    <div class="col-md-12 text-center" style="border-top: 1px solid aquamarine">
      <br/>

      <h2 class="title tulum-party-title" style="margin-bottom: 0">
        EVENT LINE
      </h2>
      <p>Take a deep dive into the dark cenote <br> MAX DEPTH: UNKNOWN</p>
      <br>
      <div class="text-center">
        <img
          v-lazy="birdsImg+'?w=200&h=100'"
          class="centered"
          width="200px"
          height="100px">
      </div>
      <br>
      <br>

    </div>
    <div
      class="text-center"
      style=" background: linear-gradient(#2a737a, black);border-top: 1px solid aquamarine"
    >
      <h4
        class="section-header text-left"
      >
        UPCOMING EVENTS
      </h4>
      <time-line>
        <time-line-item
          v-for="(event, idx) in incomingParties"
          :key="event._id"
          :full="true"

          :inverted="idx % 2 === 1"
          :badge-type="badgeType(idx)"
        >
          <div slot="content" class="card-link">
           <pre style="background-color: #0e0e0e; opacity: 0.3"
           >{{ idx + 1 }} mts</pre
           >
            <EventCard
              :loading="event.loading"
              v-bind:event="event"
              :key="event._id"
              :idx="idx"
            ></EventCard>
          </div>
        </time-line-item>
      </time-line>
    </div>
    <div class="text-center">
      <router-link
        class="btn btn-call-for-action"
        :to="{ path: '/tulum-party-mexico-event-calendar?period=this_month' }"
      >Full Event Calendar
      </router-link>
      <br/>
      <br/>
    </div>
  </div>
</template>
<script>
import {TimeLine, TimeLineItem} from 'src/components';
import EventCard from '../../components/EventCard/EventCard';
// import moment from 'moment';
import ClubActivity from '@/components/ClubActivity/ClubActivity';
import HomeMainImage from '@/pages/Home/HomeMainImage';

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );
// const DynamicCard = () => import('@/components/Cards/DynamicCard');

export default {
  name: 'Home',
  created: function () {
    this.$store.dispatch('getParties');
  },
  metaInfo() {
    return {
      title:
        'TULUM PARTY · Parties, Nightlife & Techno Music · Tulum Top Events & Clubs',
      // link: [{rel: 'preload', as: 'image',
      //   href: this.mainImage}],
      meta: [
        {
          name: 'description',
          content:
            'TULUM.PARTY - We put together the best events happening around Tulum. Tickets and Reservations for the best parties & clubs. Join the largest party community',
        },
        {
          name: 'keywords',
          content:
            'Tulum, Parties, Party, DJ, Techno, nightlife, clubs, Festival, Vagalume, Mia, Tonight, Zamna, 2021, Papaya Playa, Fullmoon, Events, Tickets, Bagatelle, Playa del Carmen',
        },
        {
          property: 'og:site_name',
          content: `TULUM PARTY`,
        },
        {
          property: 'og:title',
          content: `TULUM PARTY: Parties, Nightlife & Techno Music. Top Events & Clubs In Tulum`,
        },
        {property: 'og:type', content: 'website'},
        {
          property: 'og:description',
          content: `TULUM.PARTY - We put together the best events happening around Tulum. Tickets and Reservations for the best parties & clubs. Recommendations and reviews.`,
        },
        {property: 'og:locality', content: 'Tulum'},
        {property: 'og:country-name', content: 'Mexico'},
        {
          property: 'og:image',
          content: this.mainImage,
        },
      ],
    };
  },
  data: () => {
    return {
      modal: {},
      showPalms: false,
      showingAll: false,
      events: [],
      showSearch: true,
      lenteEye: 'https://imgix.cosmicjs.com/d944c4a0-9cf4-11ee-93ed-8bfbf1aced8f-image.png',
      zamnaTulum: 'https://imgix.cosmicjs.com/47039b80-cc42-11ed-a5c3-fb6dd2500767-afterlifetulum.jpg',
      follow: 'https://imgix.cosmicjs.com/89de8e60-9a96-11ee-b34c-99d1d89e42eb-IMG5765.jpg',
      dayzero: 'https://imgix.cosmicjs.com/56be9900-58f8-11ef-b1ea-f56c65dfade9-little-ibiza-day.jpg',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&duotone=aquamarine,FA8072&duotone-alpha=100&auto=format',
      clubsSpirt:
        "https://imgix.cosmicjs.com/801be930-9c9f-11ee-93ed-8bfbf1aced8f-image.png",
      birdsImg: "https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png",
      calendarImg:
        'https://imgix.cosmicjs.com/e02422c0-54f5-11ef-b1ea-f56c65dfade9-eye-lex.jpg',
      calendarImg2:
        'https://imgix.cosmicjs.com/d38e0f20-9a39-11ee-b34c-99d1d89e42eb-aqua-eye.png',
      logito:
        'https://imgix.cosmicjs.com/846479a0-9cf2-11ee-93ed-8bfbf1aced8f-image.png',
      instagram:
        'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      mainImage:
        'https://imgix.cosmicjs.com/3c6c5b60-96ea-11eb-b593-972a7dbc1054-papaya-playa-small.png?h=200&w=400&auto=format&fit=fillmax&fill=blur',
      marcianitos:
        'https://imgix.cosmicjs.com/dbcd2d20-afde-11ec-97bc-19d12908cbbe-image.png',
    };
  },
  components: {
    HomeMainImage,
    EventCard,
    TimeLine,
    TimeLineItem,
    ClubActivity,
    VueWhatsappWidget,
  },
  methods: {
    onQuickReserveClick() {
      window.ga('send', 'event', 'reservations', 'PartySupport', 'Main', 20);
      this.$rollbar.info('PartySupport');
    },
    showMore(id) {
      this.modal = {};
      this.modal[id] = true;
    },
    showLess(id) {
      this.modal = {};
      this.modal[id] = false;
    },
    goToParty(event) {
      this.$rollbar.info('QUICK PARTY: ' + event.target.value);
      if (event.target.value === 'calendar') {
        this.$router.push({
          path: '/tulum-party-mexico-event-calendar',
          replace: true,
        });
        return;
      }
      if (event.target.value === 'zamna') {
        this.$router.push({path: '/brands/zamna-tulum', replace: true});
        return;
      }
      this.$router.push({
        path: '/event/' + event.target.value,
        replace: true,
      });
    },
    formatDate(event) {
      let date = new Date(event.metadata.date).toISOString();
      return `${date.split('T')[0].split('-')[1]}/${
        date.split('T')[0].split('-')[2]
      } >> ${event.title.split('@')[0]}`;
    },
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },
  updated() {
    setTimeout(() => (this.showPalms = true), 5000);
  },
  computed: {
    partiesTotal: {
      get() {
        return this.$store.state.parties_total;
      },
    },
    article: {
      get() {
        return this.$store.state.article;
      },
    },
    incomingParties: {
      get() {
        return this.$store.state.incomingParties;
      },
    },
  },
};
</script>
